<template>
  <Card class="w-full mb-6" padding="p-3.5 sm:p-6">
    <div>
      <div class="flex flex-wrap items-center gap-6 mb-6">
        <h4 class="flex flex-auto text-3xl font-semibold">List Approver di Sistem</h4>
        <div class="flex flex-col w-full gap-6 sm:flex-none sm:flex-row sm:w-auto">
          <InputGista type=text
            placeholder="Search"
            v-model="search"
            >
            <template #icon-left>
              <span class="text-xl icon-search text-primary"></span>
            </template>
          </InputGista>
          <AddOfficial />
        </div>
      </div>
      <TableCDD :search="search" :isLoadingTable="isLoadingTable" @changeIsLoadingTable="changeIsLoadingTable" v-if="menuOfficialSignature?.canRead" />
    </div>
	</Card>
</template>

<script>
import Card from '@/core/components/custom/Card.vue'
import AddOfficial from '@/modules/module-config/components/AddOfficial.vue';
import TableCDD from '@/modules/module-config/components/TableCDD.vue';

export default {
  name: 'CDD',
  data () {
    return {
      search: '',
      isLoadingTable: true,
      init: true,
      timeout: null,
    }
  },
  computed: {
    menuOfficialSignature() {
			return this.$store.getters['dashboard/menuOfficialSignature']
		},
  },
  methods: {
    changeIsLoadingTable(e) {
			this.isLoadingTable = e
		}
  },
	watch: {
		search() {
			if (this.timeout) {
				clearTimeout(this.timeout)
			}
			this.timeout = setTimeout(async () => {
				this.isLoadingTable = true
        await this.$store.dispatch('config/getApproverList', { page: 1, size: 10, search: this.search })
				this.isLoadingTable = false
			}, 500)
		}
	},
	async mounted() {
    this.isLoadingTable = true
		await this.$store.dispatch('config/getApproverList', { page: 1, size: 10 })
		this.isLoadingTable = false
	},
  components: {
    Card,
    AddOfficial,
    TableCDD
  }
}
</script>