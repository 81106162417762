<template>
    <Bar 
      :chart-data="chartData" 
      :chart-options="datalabel? chartOptionsLabeled : chartOptions" 
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"/>
  </template>
  
  <script>
  import { Bar } from 'vue-chartjs/legacy'
  import { Chart,
    BarElement,
    PointElement,
    BarController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle } from 'chart.js'
  Chart.register(
    BarElement,
    PointElement,
    BarController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle);
  
    export default {
      name: 'VueChart',
      props: {
        chartId: {
          type: String,
          default: 'bar-chart'
        },
        datasetIdKey: {
          type: String,
          default: 'label'
        },
        width: {
          type: Number,
          default: 400
        },
        height: {
          type: Number,
          default: 250
        },
        cssClasses: {
          default: '',
          type: String
        },
        styles: {
          type: Object,
          default: () => ({})
        },
        plugins: {
          type: Array,
          default: () => [{
            beforeInit: function (chart) {
              chart.data.labels.forEach(function (e, i, a) {
                if (/\n/.test(e)) {
                  a[i] = e.split(/\n/)
                }
              })
            }
          }]
        },
        dataApi: {
          type: Array,
          default: () => []
        },
        backgroundColor: {
          type: String,
          default: '#30D5C9'
        },
        datalabel: {
          type: Boolean,
          default: false,
        }
      },
      data() {
        return {
          chartOptions: {
            responsive: true,
            maintainAspectRatio: true,
            lineTension: 1,
            plugins: {
              legend: {
                display: false 
              },
              datalabels: {
                display: function() {
                  return false
                }
              },
              tooltip: {
                usePointStyle: true,
                callbacks: {
                  title: function(context) {
                    const label = context[0].label
                    return label.replace(/,/g, '');
                  }
                }
              }
            },
            scales: {
              y: {
                ticks: {
                  padding: 10,
                  font: function (context) {
                    const width = context.chart.width;
                    let size = Math.round(width / 40);
                    if(width > 400) size = 10
                    return {
                      size,
                    }
                  },
                },
                grace: 1
              },
              x: {
                grid: {
                  display: false,
                },
                ticks: {
                  font: function (context) {
                    const width = context.chart.width;
                    let size = Math.round(width / 40);
                    if(width > 400) size = 10
                    return {
                      size,
                    }
                  },
                  autoSkip: false,
                  maxRotation: 0,
                  minRotation: 0,
                },
              },
            },
          },
          chartOptionsLabeled: {
            responsive: true,
            maintainAspectRatio: false,
            lineTension: 1,
            plugins: {
              legend: {
                  display: false 
              },
              datalabels: {
                  formatter: (value) => {
                      return value;
                  },
                  anchor: 'end',
                  font: function (context) {
                    const width = context.chart.width;
                    let size = Math.round(width / 40);
                    if(width > 400) size = 10
                    return {
                      size,
                    }
                  },
                  align: 'end',
                  offset: 1,
                  clamp: true
              },
              tooltip: {
                usePointStyle: true,
                callbacks: {
                  title: function(context) {
                    const label = context[0].label
                    return label.replace(/,/g, '');
                  }
                }

              }
            },
            scales: {
              y: {
                ticks: {
                  padding: 10,
                  font: function (context) {
                    const width = context.chart.width;
                    let size = Math.round(width / 40);
                    if(width > 400) size = 10
                    return {
                      size,
                    }
                  },
                },
                grace: 1
              },
              x: {
                grid: {
                  display: false,
                },
                ticks: {
                  font: function (context) {
                    const width = context.chart.width;
                    let size = Math.round(width / 40);
                    if(width > 400) size = 10
                    return {
                      size,
                    }
                  },
                  autoSkip: false,
                  maxRotation: 0,
                  minRotation: 0,
                },
              },
            },
          },
        }
      },
      computed: {
        chartDataName() {            
            return this.dataApi
        },
        chartLabels() {
          let data = this.chartDataName.map(e => {
            if(e.name.length > 14) {
              return e.name.replace(/[\s\S]{1,14}(?!\S)/g, '$&\n')
            } else return e.name
          })
          return data
        },
        chartData() {
          return {
            labels: this.chartLabels,
            datasets: [
                {
                    label: '',
                    backgroundColor: this.backgroundColor,
                    data: this.chartDataName.map(e => e.slaCount)
                }
            ],
          }
        }
      },
      components: {
        Bar
      }
    }
  
  </script>