<template>
	<div class="">
		<ButtonGista @click="toggle" color="primary" customClass="w-full sm:w-20">
			Add
		</ButtonGista>
		<GistaModal :value="isOpen" width="2/3" @input="toggle" :hideClose="true" customClass="bg-white top-0 bottom-0 mt-6 mb-8 max-w-7xl">
			<div class="px-6 py-6">
				<ValidationObserver v-slot="{ handleSubmit }">
					<div class="flex flex-wrap gap-6 pb-6 mb-6 border-b border-gray-lightest">
						<div class="flex items-center flex-auto">
							<h4 class="text-xl font-semibold">Tambah Pejabat</h4>
						</div>
						<div class="flex flex-none flex-col sm:flex-row gap-2.5 justify-center w-full sm:w-auto">
							<button-gista type="secondary" color="error" @click="toggle">Cancel</button-gista>
							<button-gista type="primary" @click="handleSubmit(clickAdd)">Save</button-gista>
						</div>
					</div>
					<div class="flex flex-col w-full gap-6 mb-6 md:flex-row">
						<div class="w-full">
							<ValidationProvider name="officialName" rules="required" v-slot="{ errors }">
								<AutocompleteNewSolutip
									label="Nama Pejabat"
									placeholder="(Search)"
									:options="listEmployee"
									@updateInput="inputSearch($event)"
									@updateAutoComplete="changeOfficial($event)"
									@focus="focusOfficial"
									@blur="blurOfficial"
									v-model="newApproverNameLocalState"
									:isLoading="isLoading"
									:error="!!errors[0]"
									textField="fullName" mandatory
									iconLeft="icon-search text-xl text-gray-light">
									<template #message>
										<p class="mt-1 text-xs text-error">{{ errors[0] }}</p>
									</template>
								</AutocompleteNewSolutip>
							</ValidationProvider>
						</div>
						<InputDisabled label="Job Title" :value="selected.jobTitle" class="w-full"/>
						<InputDisabled label="Udomain" :value="selected.userDomain" class="w-full" />
					</div>
				</ValidationObserver>
			</div>

		</GistaModal>
	</div>
</template>
<script>
import { MESSAGE_SUCCESS_SAVE_OFFICIAL } from '@/core/constant/successMessage'
import AutocompleteNewSolutip from '@/core/components/custom/AutocompleteNew'
import InputDisabled from '@/core/components/custom/InputDisabled.vue'

export default {
	name: 'AddOfficial',
	data() {
		return {
			isOpen: false,
			selected: {
				fullName:'',
				jobTitle: '(auto-filled)',
				userDomain: '(auto-filled)'
			},
			isLoading: false,
			newApproverNameLocalState: ''
		}
	},
	computed: {
		modal: {
			get() {
				return this.$store.state.modal.modal
			},
			set(value) {
				this.$store.commit('modal/SET_MODAL', value)
			}
		},
		listEmployee() {
			return this.$store.state.config.listEmployee
		},
	},
	methods: {
		toggle() {
			this.isOpen = !this.isOpen
			this.selected = {
				fullName:'',
				jobTitle: '(auto-filled)',
				userDomain: '(auto-filled)'
			}
			this.newApproverNameLocalState = ''
		},
    	async inputSearch(e) {
			this.isLoading = true
			await this.$store.dispatch('config/getEmployeeList', { name: e })
      		this.isLoading = false
		},
		async focusOfficial() {
			this.isLoading = true
			await this.$store.dispatch('config/getEmployeeList', { name: this.newApproverNameLocalState })
			this.isLoading = false
		},
		changeOfficial(event) {
			this.selected = event
			this.newApproverNameLocalState = event.fullName
		},
		blurOfficial() {
			if (!this.newApproverNameLocalState) {
				this.selected.fullName = ''
				this.selected.jobTitle = ''
				this.selected.userDomain = ''
			}
			this.newApproverNameLocalState = this.selected.fullName
		},
		async clickAdd() {
			try {
				this.modal.modalLoading = true
                const formData = new FormData()
                formData.append('karyawanId', this.selected.nip)
				const response = await this.$store.dispatch('config/postNewOfficialSignature', formData)
				if (response?.status < 300 && response?.status >= 200) {
					this.modal.modalLoading = false
					this.isOpen = !this.isOpen
					this.selected = ''
					this.modal.modalSuccessSaved = { value: true, title: MESSAGE_SUCCESS_SAVE_OFFICIAL }
					await this.$store.dispatch('config/getApproverList', { page: 1, size: 10 })
				} else {
					this.$store.dispatch('modal/throwError', { response })
				}
			} catch (error) {
				this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
		}
	},
	components: {
		AutocompleteNewSolutip,
		InputDisabled
	}
}

</script>