<template>
  <LineChartGenerator 
    :chart-data="chartData" 
    :chart-options="chartOptions" 
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"/>
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  ChartJSPluginDatalabels
);

  export default {
    name: 'EfficiencyGraph',
    props: {
      sla: {
        type: Boolean,
        default: false
      },
      chartId: {
        type: String,
        default: 'bar-chart'
      },
      datasetIdKey: {
        type: String,
        default: 'label'
      },
      width: {
        type: Number,
        default: 400
      },
      height: {
        type: Number,
        default: 250
      },
      cssClasses: {
        default: '',
        type: String
      },
      styles: {
        type: Object,
        default: () => ({})
      },
      plugins: {
        type: Array,
        default: () => []
      },
      startYear: {
        type: Number,
        default: 2021
      },
      endYear: {
        type: Number,
        default: 2022
      },
      dataApi: {
        type: Array,
        default: () => []
      },
      roundTooltip: {
        type: Boolean,
        default: false
      },
      keyName: {
        type: String,
        default: 'total'
      },
      backgroundColor: {
        type: String,
        default: '#30D5C9'
      },
      backgroundColor2: {
        type: String,
        default: '#797EF6'
      },
    },
    data() {
      return {
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              align: 'end',
              labels: {
                usePointStyle: true,
              },
            },
            datalabels: {
              formatter: (value) => {
                return value+"%";
              },
              anchor: 'end',
              font: function (context) {
                const width = context.chart.width;
                let size = Math.round(width / 40);
                if(width > 400) size = 10
                return {
                  size,
                  weight: 'bold',
                }
              },
              padding: 2,
              align: 'end',
              offset: 1,
              clamp: true
            },
            ...this.roundTooltip ? 
            {tooltip: {
              usePointStyle: true,
              callbacks: {
                label: (item) => `${item.dataset.label}: ${item.formattedValue}%`
              }
            }}: {}
          },

          scales: {
            y: {
              ticks: {
                font: {
                  size: 10
                },
                callback: function(value) {
                  return value + "%"
                },
              },
              grace: 1
            },
            x: {
              grid: {
                display: false,
              },
              ticks: {
                font: {
                  size: 10
                },
                maxRotation: 0,
                minRotation: 0
              },
              offset: true
            },
          }

        },
      }
    },
    computed: {
      chartStartYear() {
        return this.dataApi?.filter(e => e.year === +this.startYear)
      },
      chartEndYear() {
        return this.dataApi?.filter(e => e.year === +this.endYear)
      },
      chartLabels() {
        return this.chartStartYear.map(e => e.monthMMM_ENG)
      },
      chartData() {
        return {
          labels: this.chartLabels,
          datasets: [
            {
              label: this.startYear,
              backgroundColor: this.backgroundColor,
              borderColor: this.backgroundColor,
              data: this.chartStartYear.map(e => Number(e.value.replace(/,/g, '.'))),
              datalabels: {
                anchor: 'start',
                align: 'start'
              },

            },
            {
              label: this.endYear,
              backgroundColor: this.backgroundColor2,
              borderColor: this.backgroundColor2,
              data: this.chartEndYear.map(e => Number(e.value.replace(/,/g, '.'))),
              datalabels: {
                anchor: 'end',
                align: 'end'
              },

            }
          ],
        }
      },
    },
    components: {
      LineChartGenerator
    }
  }

</script>