<template>
	<div class="flex justify-center">
		<div class="w-full">
			<div class="flex w-full my-6">
				<h4 class="mr-6 text-xl md:text-2xl lg:text-3xl">Digital Approval</h4>
			</div>
			<CDD v-if="menuOfficialSignature?.canRead"/>
		</div>
	</div>
</template>

<script>
import CDD from '@/modules/module-config/pages/CDD.vue'

export default {
	name: "Config",
	computed: {
    menuOfficialSignature() {
			return this.$store.getters['dashboard/menuOfficialSignature']
		},
	},
	methods: {
		changeActiveMenu(val) {
			this.activeMenu = val
		},
	},
	components: {
		CDD
	}
};
</script>
