<template>
  <div>
    <div class="flex flex-col flex-wrap w-full gap-4 sm:flex-nowrap sm:flex-row sm:overflow-auto" v-dragscroll="true">
      <div class="w-full border rounded-lg border-gray-lightest shadow-gray-sm">
        <div class="p-4 min-w-max">
          <div class="flex gap-2.5 mb-4">
            <h5 class="flex-auto text-lg font-semibold text-gray">Total Accumulation RFP</h5>
            <img src="@/core/assets/icons/Dashboard/Buat PO.svg" class="flex-none w-8" alt="Buat PO" />
          </div>
          <div>
            <h5 class="text-5xl font-semibold leading-normal text-secondary-darkest">{{cardInformation.totalAccumulationRfp}}</h5>
          </div>
        </div>
      </div>
      <div class="w-full border rounded-lg border-gray-lightest shadow-gray-sm">
        <div class="p-4 min-w-max">
          <div class="flex gap-2.5 mb-4">
            <h5 class="flex-auto text-lg font-semibold text-gray">Total Accumulation RFQ</h5>
            <img src="@/core/assets/icons/Dashboard/List PO.svg" class="flex-none w-8" alt="List PO" />
          </div>
          <div>
            <h5 class="text-5xl font-semibold leading-normal text-secondary-darkest">{{cardInformation.totalAccumulationRfq}}</h5>
          </div>
        </div>
      </div>
      <div class="w-full border rounded-lg border-gray-lightest shadow-gray-sm">
        <div class="p-4 min-w-max">
          <div class="flex gap-2.5 mb-4">
            <h5 class="flex-auto text-lg font-semibold text-gray">SLA RFX (This Month)</h5>
            <img src="@/core/assets/icons/Dashboard/Dashboard.svg" class="flex-none w-8" alt="Dashboard" />
          </div>
          <div>
            <h5 class="text-5xl font-semibold leading-normal text-secondary-darkest">{{cardInformation.slaRfxPercentage}}%</h5>
          </div>
        </div>
      </div>
      <div class="w-full border rounded-lg border-gray-lightest shadow-gray-sm">
        <div class="p-4 min-w-max">
          <div class="flex gap-2.5 mb-4">
            <h5 class="flex-auto text-lg font-semibold text-gray">SLA BAN (This Month)</h5>
            <img src="@/core/assets/icons/Dashboard/Dashboard.svg" class="flex-none w-8" alt="Dashboard" />
          </div>
          <div>
            <h5 class="text-5xl font-semibold leading-normal text-secondary-darkest">{{cardInformation.slaBanPercentage}}%</h5>
          </div>
        </div>
      </div>
      <div class="w-full border rounded-lg border-gray-lightest shadow-gray-sm">
        <div class="p-4 min-w-max">
          <div class="flex gap-2.5 mb-4">
            <h5 class="flex-auto text-lg font-semibold text-gray">Efficiency (This Month)</h5>
            <img src="@/core/assets/icons/Dashboard/Dashboard.svg" class="flex-none w-8" alt="Dashboard" />
          </div>
          <div>
            <h5 class="text-5xl font-semibold leading-normal text-secondary-darkest">{{cardInformation.efficiency}}%</h5>
          </div>
          <div>
            <h5 class="text-gray">Rp {{cardInformation.efficiencyNegotiation}}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dragscroll } from 'vue-dragscroll'

export default {
  name: "CardInformation",
  computed: {
    cardInformation() {
      return this.$store.state.report.cardInformation
    }
  },
  directives: {
		dragscroll
	},

}
</script>