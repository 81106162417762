<template>
	<Card v-if="menuReportChart?.canRead" class="w-full mb-6" padding="p-3.5 sm:p-6">
		<div class="flex gap-3.5 flex-col justify-between w-full mb-6 sm:flex-row">
			<h4 class="text-3xl font-semibold">Report</h4>
			<DownloadReport  v-if="menuReportDownload?.canRead" />
		</div>

		<div class="flex flex-col flex-wrap w-full gap-4 pb-6 sm:flex-nowrap sm:flex-row sm:overflow-auto" v-dragscroll="true">
			<div class="w-full p-3 rounded-lg shadow-gray-sm">
				<div class="flex justify-between mb-6">
					<h3>RFX</h3>
					<SelectChart :options="dateListOption" @click="changeYear($event, 'rfx')"/>
				</div>
				<VueChart :startYear="rfx.startYear" :endYear="rfx.endYear" :dataApi="chartDataRFX"></VueChart>
			</div>
			<div class="w-full p-3 rounded-lg shadow-gray-sm">
				<div class="flex justify-between mb-6">
					<h3>RFP</h3>
					<SelectChart :options="dateListOption" @click="changeYear($event, 'rfp')"/>
				</div>
				<VueChart :startYear="rfp.startYear" :endYear="rfp.endYear" :dataApi="chartDataRFP"></VueChart>
			</div>
			<div class="w-full p-3 rounded-lg shadow-gray-sm">
				<div class="flex justify-between mb-6">
					<h3>RFQ</h3>
					<SelectChart :options="dateListOption" @click="changeYear($event, 'rfq')"/>
				</div>
				<VueChart :startYear="rfq.startYear" :endYear="rfq.endYear" :dataApi="chartDataRFQ"></VueChart>
			</div>
			<!-- <div class="w-full p-3 rounded-lg shadow-gray-sm">
				<div class="flex justify-between mb-6">
					<h3>RFI</h3>
					<SelectChart :options="dateListOption" @click="changeYear($event, 'rfi')"/>
				</div>
				<VueChart :startYear="rfi.startYear" :endYear="rfi.endYear" :dataApi="chartDataRFI"></VueChart>
			</div> -->
		</div>
	</Card>
</template>

<script>
import VueChart from './VueChart.vue'
import DownloadReport from '@/core/components/modal/DownloadReport'
import Card from '@/core/components/custom/Card.vue'
import { dragscroll } from 'vue-dragscroll'
import SelectChart from '@/core/components/custom/SelectChart.vue';

export default {
	name: "Reports",
	directives: {
		dragscroll
	},
	props: ["isShow"],
	data() {
		return {
			rfx: {
				startYear: new Date().getFullYear() - 1,
				endYear: new Date().getFullYear(),
			},
			rfp: {
				startYear: new Date().getFullYear() - 1,
				endYear: new Date().getFullYear(),
			},
			rfq: {
				startYear: new Date().getFullYear() - 1,
				endYear: new Date().getFullYear()
			},
			rfi: {
				startYear: new Date().getFullYear() - 1,
				endYear: new Date().getFullYear()
			}
		}
	},
	computed: {
		chartDataRFX() {
			return this.$store.state.report.chartDataRFX
		},
		chartDataRFP() {
			return this.$store.state.report.chartDataRFP
		},
		chartDataRFQ() {
			return this.$store.state.report.chartDataRFQ
		},
		chartDataRFI() {
			return this.$store.state.report.chartDataRFI
		},
		menuReportChart() {
			return this.$store.getters['dashboard/menuReportChart']
		},
		menuReportDownload() {
			return this.$store.getters['dashboard/menuReportDownload']
		},
		dateListOption() {
			const date = new Date()
			const dateGetYear = date.getFullYear()
			return [ dateGetYear-3, dateGetYear-2, dateGetYear-1, dateGetYear ]
		},
	},
	watch: {

	},
	methods: {
		changeYear(e, key) {
			const startYear = e.startYear
			const endYear = e.endYear
			this[key].startYear = startYear
			this[key].endYear = endYear
			this.$store.dispatch('report/getReportChart', { startYear: startYear, endYear: endYear, moduleName: key})
		},
	},
	components: { 
		DownloadReport, 
		Card, 
		VueChart, 
		SelectChart 
	}
}
</script>