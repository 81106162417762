<template>
  <div>
    <div class="flex mt-6">
      <div class="w-full">
        <div class="animate-pulse">
          <div class="h-44 rounded-lg bg-gray-lightest rounded w-full mb-3.5"></div>
          <div class="h-72 rounded-lg bg-gray-lightest rounded w-full mb-3.5"></div>
          <div class="h-72 rounded-lg bg-gray-lightest rounded w-full mb-3.5"></div>
          <div class="h-72 rounded-lg bg-gray-lightest rounded w-full mb-3.5"></div>
          <div class="h-72 rounded-lg bg-gray-lightest rounded w-full mb-3.5"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DashboardLoading',
}
</script>
<style scoped>
.max-w-40 {
  max-width: 20rem;
}
</style>