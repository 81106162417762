<template>
	<div v-if="menuReportChart?.canRead">
		<DashboardLoading v-if="loading" />
		<div v-if="!loading" class="sm:p-6 p-3.5 rounded-2xl shadow-gray-sm">
			<div class="flex flex-col w-full gap-6 mb-6 sm:items-center sm:flex-row">
				<h2 class="flex-auto text-3xl font-semibold text-gray-darkest">Dashboard</h2>
				<DownloadReport v-if="menuReportDownload?.canRead" class="flex-none w-full sm:w-auto" />
			</div>
			<CardInformation class="mb-6" />
			<div class="flex flex-col flex-wrap w-full gap-4 pb-6 sm:flex-nowrap sm:flex-row sm:overflow-auto" v-dragscroll="true">
				<div class="w-full p-3 rounded-lg shadow-gray-sm">
					<div class="flex justify-between mb-6">
						<h3>Top 5 Average RFx</h3>
						<SelectChartAvg label=""
							size="small"
							placeholder="Select"
							:options="dateListAvgOption"
							@change="(e) => changeYearAvg(e, 'top5Rfx')"
							:error="false"
							:value="`${avg.rfx}`"
							iconLeft="icon-filter text-xl text-gray"
						></SelectChartAvg>
					</div>
					<AverageChart :datalabel="true" :dataApi="chartAvgRfx"></AverageChart>
				</div>
				<div class="w-full p-3 rounded-lg shadow-gray-sm">
					<div class="flex justify-between mb-6">
						<h3>Top 5 Average Memo</h3>
						<SelectChartAvg label=""
							size="small"
							placeholder="Select"
							:options="dateListAvgOption"
							@change="(e) => changeYearAvg(e, 'top5Approval')"
							:error="false"
							:value="`${avg.memo}`"
							iconLeft="icon-filter text-xl text-gray"
						></SelectChartAvg>
					</div>
					<AverageChart :datalabel="true" :dataApi="chartAvgApproval"></AverageChart>
				</div>
				<div class="w-full p-3 rounded-lg shadow-gray-sm">
					<div class="flex justify-between mb-6">
						<h3>Top 5 Average Process</h3>
						<SelectChartAvg label=""
							size="small"
							placeholder="Select"
							:options="dateListAvgOption"
							@change="(e) => changeYearAvg(e, 'top5Doc')"
							:error="false"
							:value="`${avg.process}`"
							iconLeft="icon-filter text-xl text-gray"
						></SelectChartAvg>
					</div>
					<AverageChart :datalabel="true" :dataApi="chartAvgDoc"></AverageChart>
				</div>
			</div>
			<div class="flex flex-col flex-wrap w-full gap-4 pb-6 sm:flex-nowrap sm:flex-row sm:overflow-auto" v-dragscroll="true">
				<div class="w-full p-3 rounded-lg shadow-gray-sm">
					<div class="flex justify-between mb-6">
						<h3>RFX</h3>
						<SelectChart iconLeft="icon-filter text-xl text-gray" :graph="true" :options="dateListOption" @click="changeYear($event, 'rfx')"/>
					</div>
					<VueChart :roundTooltip="true" backgroundColor="#ACB7FF" backgroundColor2="#85ECE4" :startYear="rfx.startYear" :endYear="rfx.endYear" :dataApi="chartDataRFX"></VueChart>
				</div>
				<div class="w-full p-3 rounded-lg shadow-gray-sm">
					<div class="flex justify-between mb-6">
						<h3>RFP</h3>
						<SelectChart iconLeft="icon-filter text-xl text-gray" :graph="true" :options="dateListOption" @click="changeYear($event, 'rfp')"/>
					</div>
					<VueChart :roundTooltip="true" backgroundColor="#ACB7FF" backgroundColor2="#85ECE4" :startYear="rfp.startYear" :endYear="rfp.endYear" :dataApi="chartDataRFP"></VueChart>
				</div>
				<div class="w-full p-3 rounded-lg shadow-gray-sm">
					<div class="flex justify-between mb-6">
						<h3>RFQ</h3>
						<SelectChart iconLeft="icon-filter text-xl text-gray" :graph="true" :options="dateListOption" @click="changeYear($event, 'rfq')"/>
					</div>
					<VueChart :roundTooltip="true" backgroundColor="#ACB7FF" backgroundColor2="#85ECE4" :startYear="rfq.startYear" :endYear="rfq.endYear" :dataApi="chartDataRFQ"></VueChart>
				</div>
			</div>
			<div class="flex flex-col flex-wrap w-full gap-2 pb-2 mt-2 sm:mt-6 sm:pb-6 sm:gap-4 sm:flex-nowrap sm:flex-row sm:overflow-auto" v-dragscroll="true">
				<div class="w-full sm:p-6 p-3.5 border rounded-lg border-gray-lightest shadow-gray-md">
					<div class="flex flex-col justify-between gap-3 mb-6 sm:items-center sm:flex-row">
						<h3>SLA RFx</h3>
						<SelectChart iconLeft="icon-filter text-xl text-gray" :graph="true" :options="dateListOption" @click="changeYearMonthlyChart($event, 'sla')"/>
					</div>
					<LineGraph :startYear="sla.startYear" :endYear="sla.endYear" :dataApi="chartSla" backgroundColor="#ACB7FF" backgroundColor2="#85ECE4" :roundTooltip="true" :sla="true"/>
				</div>
			</div>
			<div class="flex flex-col flex-wrap w-full gap-4 pb-6 sm:flex-nowrap sm:flex-row sm:overflow-auto" v-dragscroll="true">
				<div class="w-full sm:p-6 p-3.5 border rounded-lg border-gray-lightest shadow-gray-md">
					<div class="flex flex-col justify-between gap-3 mb-6 sm:items-center sm:flex-row">
						<h3>Efficiency Negotiation</h3>
						<SelectChart iconLeft="icon-filter text-xl text-gray" :graph="true" :options="dateListOption" @click="changeYearMonthlyChart($event, 'efficiency')"/>
					</div>
					<LineGraph :startYear="efficiency.startYear" :endYear="efficiency.endYear" :dataApi="chartEfficiency" backgroundColor="#ACB7FF" backgroundColor2="#85ECE4" :roundTooltip="true" :sla="true"/>
				</div>
				<div class="w-full sm:p-6 p-3.5 border rounded-lg border-gray-lightest shadow-gray-md">
					<div class="flex flex-col justify-between gap-3 mb-6 sm:items-center sm:flex-row">
						<h3>Efficiency Negotiation in Million</h3>
						<SelectChart iconLeft="icon-filter text-xl text-gray" :graph="true" :options="dateListOption" @click="changeYearMonthlyChart($event, 'efficiencyMillion')"/>
					</div>
					<VueChart keyName="value" :roundTooltip="true" :startYear="efficiencyMillion.startYear" :endYear="efficiencyMillion.endYear" :dataApi="chartEfficiencyMillion" backgroundColor="#ACB7FF" backgroundColor2="#85ECE4" :datalabel="false" :inMillion="true"></VueChart>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import SelectChart from '@/core/components/custom/SelectChart.vue';
import VueChart from '@/core/components/dashboard/VueChart.vue'
import { dragscroll } from 'vue-dragscroll'
import DownloadReport from '@/core/components/modal/DownloadReport.vue';
import CardInformation from '@/core/components/dashboard/CardInformation.vue';
import LineGraph from "@/core/components/dashboard/LineGraph.vue";
import AverageChart from '@/core/components/dashboard/AverageChart.vue';
import SelectChartAvg from '@/core/components/custom/SelectChartAvg.vue';
import DashboardLoading from '@/core/components/rfp/loading/DashboardLoading.vue';

export default {
	name: "Dashboard",
	directives: {
		dragscroll
	},
	data() {
		return {
			rfx: {
				startYear: new Date().getFullYear() - 1,
				endYear: new Date().getFullYear(),
			},
			rfp: {
				startYear: new Date().getFullYear() - 1,
				endYear: new Date().getFullYear(),
			},
			rfq: {
				startYear: new Date().getFullYear() - 1,
				endYear: new Date().getFullYear()
			},
			rfi: {
				startYear: new Date().getFullYear() - 1,
				endYear: new Date().getFullYear()
			},
			avg: {
				rfx: new Date().getFullYear(),
				memo: new Date().getFullYear(),
				process: new Date().getFullYear()
			},
			sla: {
				startYear: new Date().getFullYear() - 1,
				endYear: new Date().getFullYear(),
			},
			efficiency: {
				startYear: new Date().getFullYear() - 1,
				endYear: new Date().getFullYear(),
			},
			efficiencyMillion: {
				startYear: new Date().getFullYear() - 1,
				endYear: new Date().getFullYear(),
			},
			loading: true,
			cardLoading: {
				sla: true,
				efficiency: true,
				efficiencyMillion: true,
			}
		}
	},
	computed: {
		chartDataRFX() {
			return this.$store.state.report.chartDataRFX
		},
		chartDataRFP() {
			return this.$store.state.report.chartDataRFP
		},
		chartDataRFQ() {
			return this.$store.state.report.chartDataRFQ
		},
		chartDataRFI() {
			return this.$store.state.report.chartDataRFI
		},
		chartAvgRfx() {
			return this.$store.state.report.chartAvgRfx
		},
		chartAvgApproval() {
			return this.$store.state.report.chartAvgApproval
		},
		chartAvgDoc() {
			return this.$store.state.report.chartAvgDoc
		},
		chartSla() {
			return this.$store.state.report.chartSla
		},
		chartEfficiency() {
			return this.$store.state.report.chartEfficiency
		},
		chartEfficiencyMillion() {
			return this.$store.state.report.chartEfficiencyMillion
		},
		menuReportChart() {
			return this.$store.getters['dashboard/menuReportChart']
		},
		menuReportDownload() {
			return this.$store.getters['dashboard/menuReportDownload']
		},
		dateListOption() {
			const date = new Date()
			const dateGetYear = date.getFullYear()
			return [ dateGetYear-3, dateGetYear-2, dateGetYear-1, dateGetYear ]
		},
		dateListAvgOption() {
			const date = new Date()
			const dateGetYear = date.getFullYear()
			return [ dateGetYear, dateGetYear-1 ]
		},
	},
	methods: {
		changeYear(e, key) {
			const startYear = e.startYear
			const endYear = e.endYear
			this[key].startYear = startYear
			this[key].endYear = endYear
			this.$store.dispatch('report/getReportChart', { startYear: startYear, endYear: endYear, moduleName: key})
		},
		changeYearAvg(e, key) {
			this.avg[key] = e
			this.$store.dispatch('report/getReportChart', { startYear: e, endYear: e, moduleName: key})
		},
		async changeYearMonthlyChart(e, key) {
			try {
				this.cardLoading[key] = true
				const startYear = e.startYear
				const endYear = e.endYear
				this[key].startYear = startYear
				this[key].endYear = endYear
				await this.$store.dispatch('report/getDashboardMonthlyChart', { startYear: startYear, endYear: endYear, moduleName: key})
				this.cardLoading[key] = false
			} catch (e) {
				this.cardLoading[key] = false
			}
		},
	},
	async mounted() {
		this.loading = true
		await this.$store.dispatch('report/getDashboardTop')
		this.loading = false
	},
	components: { 
		VueChart, 
		SelectChart,
		DownloadReport,
		CardInformation,
		LineGraph,
		AverageChart,
		SelectChartAvg,
		DashboardLoading
	}
}
</script>
