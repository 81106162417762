<template>
	<div class="">
		<div class="w-full overflow-auto max-h-custom">
			<div class="w-full">
				<table class="relative w-full border-collapse" aria-describedby="tablecdd">
					<thead class="sticky top-0 z-20 h-10 py-1 bg-white">
						<tr class="truncate">
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pl-2 pr-8 font-medium leading-normal text-left border-b-2 text-gray-darkest border-primary-lightest">No</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pl-2 pr-8 font-medium leading-normal text-left border-b-2 text-gray-darkest border-primary-lightest">Nama Pejabat</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pl-2 pr-8 font-medium leading-normal text-left border-b-2 text-gray-darkest border-primary-lightest">Job Title</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pl-2 pr-8 font-medium leading-normal text-left border-b-2 text-gray-darkest border-primary-lightest">Udomain</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pl-2 pr-8 font-medium leading-normal text-left border-b-2 text-gray-darkest border-primary-lightest">Status</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pl-2 pr-8 font-medium leading-normal text-left border-b-2 text-gray-darkest border-primary-lightest">Action</h5>
							</th>
						</tr>
					</thead>
					<tbody v-if="!isLoadingTable">
						<tr v-for="(n,i) in listApprover?.content" :key="i" :class="{ 'bg-gray-lightest': !n.isActive, 'bg-primary-lightest': n.isActive && i % 2 !== 0}" class="border-b border-primary-lightest">
							<td>
								<div class="pl-2 pr-8 font-medium leading-normal text-gray-darkest">
                 					{{(currentPage-1)*pageCount+i+1}}
								</div>
							</td>
							<td>
								<h5 class="pr-8 pl-2 py-2.5 font-medium text-gray-darkest leading-normal truncate">{{ n.name }}</h5>
							</td>
							<td>
								<h5 class="pr-8 pl-2 py-2.5 font-medium text-gray-darkest leading-normal">{{ n.jobTitle }}</h5>
							</td>
							<td>
								<h5 class="pr-8 pl-2 py-2.5 font-medium text-gray-darkest leading-normal">{{ n.domain }}</h5>
							</td>
							<td>
								<div class="flex items-center pl-2 pr-8">
									<div v-if="n.isActive" class="flex items-center px-4 py-2 rounded-lg bg-primary">
										<span class="text-xs font-semibold leading-normal text-white">Active</span>
									</div>
									<div v-else class="flex items-center px-4 py-2 rounded-lg bg-gray-light">
										<span class="text-xs font-semibold leading-normal text-white">Inactive</span>
									</div>
								</div>
							</td>
							<td>
								<div class="flex items-center pl-2">
									<DeleteOfficial @refetch="refetch" :employeeData="n" v-if="n.isActive"/>
									<RestoreOfficial @refetch="refetch" :employeeData="n" v-else />
								</div>
							</td>
						</tr>
					</tbody>
					<tbody v-else class="animate-pulse">
						<tr v-for="i in 10" :key="i" class="border-b border-primary-light" :class="{'bg-primary-lightest': i % 2 !== 1}">
							<td class="pl-2 pr-8">
								<div class="w-6 h-4 rounded bg-gray-light"></div>
							</td>
							<td class="pr-8 pl-2 py-2.5">
								<div class="w-40 h-4 rounded bg-gray-light"></div>
							</td>
							<td class="pr-8 pl-2 py-2.5">
								<div class="w-20 h-4 rounded bg-gray-light"></div>
							</td>
							<td class="pr-8 pl-2 py-2.5">
								<div class="w-20 h-4 rounded bg-gray-light"></div>
							</td>
							<td class="pl-2 pr-8">
								<div class="w-16 h-4 rounded bg-gray-light"></div>
							</td>
							<td class="pl-2">
								<div class="w-8 h-4 rounded bg-gray-light"></div>
							</td>
						</tr>
					</tbody>
				</table>
				<div class="w-full">
					<div 
						v-if="listApprover?.content?.length === 0"
						class="flex flex-col items-center justify-center h-80"
					>
						<img 
							src="@/core/assets/icons/message/noitems.svg"
							alt="error"
							class="w-20"
						/>
						<div class="mt-3">
							<p class="text-gray">Tidak Ada Data</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="flex" v-if="listApprover?.content?.length > 0">
			<PaginationGista
				:current="currentPage"
				:total="totalRecords"
				:per-page="+pageCount"
				@page-changed="handlePageChange($event)"
				@paging-change="handlePagingChange($event)"
			>
			</PaginationGista>
		</div>
	</div>
</template>

<script>
import DeleteOfficial from '@/modules/module-config/components/DeleteOfficial.vue';
import RestoreOfficial from '@/modules/module-config/components/RestoreOfficial.vue';
export default {
	name: 'TableCDD',
  	props: ['search', 'isLoadingTable'],
	data() {
		return {
			currentPage: 0,
			totalRecords: null,
			pageCount: null,
		}
	},
  	watch: {
		listApprover: {
			handler() {
        		this.setLocalState()
			},
			deep: true
		},
	},
	computed: {
		listApprover() {
			return this.$store.state.config.listApprover
		},
	},
	methods: {
		async handlePageChange(e) {
			this.$emit('changeIsLoadingTable', true)
			await this.$store.dispatch('config/getApproverList', { page: e, size: this.pageCount, search: this.search })
			this.$emit('changeIsLoadingTable', false)
		},
		async handlePagingChange(e) {
			this.$emit('changeIsLoadingTable', true)
			await this.$store.dispatch('config/getApproverList', { page: 1, size: e , search: this.search })
			this.$emit('changeIsLoadingTable', false)
		},
		setLocalState() {
			this.currentPage = this.listApprover?.pageable.pageNumber + 1
			this.totalRecords = this.listApprover?.totalElements
			this.pageCount = +this.listApprover?.size
		},
		async refetch() {
			this.$emit('changeIsLoadingTable', true)
			await this.$store.dispatch('config/getApproverList', { page: this.currentPage, size: this.pageCount, search: this.search })
			this.$emit('changeIsLoadingTable', false)
		},
	},
	components: {
		DeleteOfficial,
		RestoreOfficial
	}
}
</script>
<style scoped>
.max-h-custom {
	max-height: 40rem;
}
.min-w-vendor {
	min-width: 16rem;
}
.min-w-status {
	min-width: 16rem;
}

</style>
