<template>
  <div
    :class="`flex flex-col justify-center h-10 p-2 rounded-lg bg-white border ${isOptionsExpanded ? 'border-secondary' : 'border-gray-light'}`"
  >
    <div class="relative w-full text-sm">
      <button
        class="flex items-center justify-between w-full text-lg rounded-md"
        :class="{
          'text-base': size != 'large',
          'h-12': (size == 'large'),
        }"
        @click="isOptionsExpanded = !isOptionsExpanded"
      >
        <div
          class="absolute inset-y-0 left-0 flex items-center pointer-events-none"
          v-if="iconLeft"
        >
          <span :class="iconLeft"></span>
        </div>
        <span
          class="flex mr-6"
          :class="{
            'pl-8': iconLeft,
            'cursor-not-allowed text-gray-light': disabled,
            'text-gray-light': value === '' || value === undefined,
            'text-gray': value !== '' && !disabled,
            'text-sm': size == 'extra-small',
            'text-base': !size,
          }"
        >
          <slot name="selected" :slot-props="selected">
            {{ selected || placeholder }}
          </slot>
        </span>
        <svg
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          class="w-4 h-4 transition-transform duration-200 ease-in-out transform"
          :class="!readOnly && isOptionsExpanded ? 'rotate-180' : 'rotate-0'"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      <transition
        enter-active-class="transition duration-500 transform ease-custom"
        enter-class="scale-y-0 -translate-y-1/2 opacity-0"
        enter-to-class="scale-y-100 translate-y-0 opacity-100"
        leave-active-class="transition duration-300 transform ease-custom"
        leave-class="scale-y-100 translate-y-0 opacity-100"
        leave-to-class="scale-y-0 -translate-y-1/2 opacity-0"
      >
        <ul
          v-show="!readOnly && isOptionsExpanded"
          class="absolute left-0 right-0 z-50 mb-4 overflow-auto bg-white rounded-md"
          :class="{
            'shadow-primary-sm':
              !disabled && (color == undefined || color == 'primary'),
            'shadow-secondary-sm': !disabled && color == 'secondary',
            'shadow-tertiary-sm': !disabled && color == 'tertiary',
          }"
        >
          <li
            v-for="(v, i) in options"
            :key="i"
            class="px-3 py-2 text-center transition-colors duration-100 text-gray hover:bg-secondary hover:text-white"
            :value="typeof v === 'object' ? v.value : v"
            :label="label"
            @click="setOption(v)"
          >
            <slot name="option" :slot-props="v">{{
              typeof v === "object" ? v.text : v
            }}</slot>
          </li>
        </ul>
      </transition>
      <div class="mt-1 text-xs text-gray" v-if="hint && !dense">
        {{ hint }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    size: {
      type: String,
      default: "medium",
    },
    disabled: Boolean,
    noBorder: {
      type: Boolean,
      default: false,
    },
    error: {
      // error flag
      type: Boolean,
      default: false,
    },
    dense: {
      // if true then theres no space for hint
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'primary'
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    label: String,
    iconLeft: String,
    hint: String,
    placeholder: {
      type: String,
      default: "Select",
    },
    options: Array, // option items
    value: {
      // for v-model
      type: [Object, String],
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: () => "",
    },
  },
  name: "SelectSolutip",
  data() {
    return {
      isOptionsExpanded: false,
      selected: typeof this.value === "object" ? this.value.text : this.value,
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    setOption(v) {
      this.isOptionsExpanded = false;
      this.computedValue = typeof v === "object" ? v.value : v;
      this.selected = typeof v === "object" ? v.text : v;
      this.$emit("change", v);
      this.$emit("input", this.value);
      this.$emit('click', v)
    },
    toggleOptions() {
      if (this.disabled) {
        this.isOptionsExpanded = false
      } else {
        this.isOptionsExpanded = !this.isOptionsExpanded
      }
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOptionsExpanded = false;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
    this.setOption(this.options[0])
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  }
};
</script>

<style>
.ease-custom {
  transition-timing-function: cubic-bezier(0.61, -0.53, 0.43, 1.43);
}
</style>
