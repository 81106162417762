var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`flex flex-col justify-center h-10 p-2 rounded-lg bg-white border ${_vm.isOptionsExpanded ? 'border-secondary' : 'border-gray-light'}`},[_c('div',{staticClass:"relative w-full text-sm"},[_c('button',{staticClass:"flex items-center justify-between w-full text-lg rounded-md",class:{
        'text-base': _vm.size != 'large',
        'h-12': (_vm.size == 'large'),
      },on:{"click":function($event){_vm.isOptionsExpanded = !_vm.isOptionsExpanded}}},[(_vm.iconLeft)?_c('div',{staticClass:"absolute inset-y-0 left-0 flex items-center pointer-events-none"},[_c('span',{class:_vm.iconLeft})]):_vm._e(),_c('span',{staticClass:"flex mr-6",class:{
          'pl-8': _vm.iconLeft,
          'cursor-not-allowed text-gray-light': _vm.disabled,
          'text-gray-light': _vm.value === '' || _vm.value === undefined,
          'text-gray': _vm.value !== '' && !_vm.disabled,
          'text-sm': _vm.size == 'extra-small',
          'text-base': !_vm.size,
        }},[_vm._t("selected",function(){return [_vm._v(" "+_vm._s(_vm.selected || _vm.placeholder)+" ")]},{"slotProps":_vm.selected})],2),_c('svg',{staticClass:"w-4 h-4 transition-transform duration-200 ease-in-out transform",class:!_vm.readOnly && _vm.isOptionsExpanded ? 'rotate-180' : 'rotate-0',attrs:{"fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M19 9l-7 7-7-7"}})])]),_c('transition',{attrs:{"enter-active-class":"transition duration-500 transform ease-custom","enter-class":"scale-y-0 -translate-y-1/2 opacity-0","enter-to-class":"scale-y-100 translate-y-0 opacity-100","leave-active-class":"transition duration-300 transform ease-custom","leave-class":"scale-y-100 translate-y-0 opacity-100","leave-to-class":"scale-y-0 -translate-y-1/2 opacity-0"}},[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(!_vm.readOnly && _vm.isOptionsExpanded),expression:"!readOnly && isOptionsExpanded"}],staticClass:"absolute left-0 right-0 z-50 mb-4 overflow-auto bg-white rounded-md",class:{
          'shadow-primary-sm':
            !_vm.disabled && (_vm.color == undefined || _vm.color == 'primary'),
          'shadow-secondary-sm': !_vm.disabled && _vm.color == 'secondary',
          'shadow-tertiary-sm': !_vm.disabled && _vm.color == 'tertiary',
        }},_vm._l((_vm.options),function(v,i){return _c('li',{key:i,staticClass:"px-3 py-2 text-center transition-colors duration-100 text-gray hover:bg-secondary hover:text-white",attrs:{"value":typeof v === 'object' ? v.value : v,"label":_vm.label},on:{"click":function($event){return _vm.setOption(v)}}},[_vm._t("option",function(){return [_vm._v(_vm._s(typeof v === "object" ? v.text : v))]},{"slotProps":v})],2)}),0)]),(_vm.hint && !_vm.dense)?_c('div',{staticClass:"mt-1 text-xs text-gray"},[_vm._v(" "+_vm._s(_vm.hint)+" ")]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }