<template>
	<MainLayout>
		<div class="flex justify-center">
			<div class="w-full">
				<div class="flex w-full my-6">
					<router-link :to="`/${PATH_ROOT}`">
						<h4
							:class="`${activeMenu === 'home'? 'text-gray-darkest': 'text-gray-light'} cursor-pointer text-xl md:text-2xl lg:text-3xl mr-6 font-semibold`"
							@click="changeActiveMenu('home')">Home</h4>
					</router-link>
					<router-link :to="`/${PATH_ROOT}/dashboard`">
						<h4 v-if="menuReportChart?.canRead"
							:class="`${activeMenu === 'dashboard'? 'text-gray-darkest': 'text-gray-light'} cursor-pointer text-xl md:text-2xl lg:text-3xl mr-6 font-semibold`"
							@click="changeActiveMenu('dashboard')">Dashboard</h4>
					</router-link>
					<router-link :to="`/${PATH_ROOT}/vendors`">
						<h4 v-if="menuVendor?.canRead"
							:class="`${activeMenu === 'vendors'? 'text-gray-darkest': 'text-gray-light'} cursor-pointer text-xl md:text-2xl lg:text-3xl mr-6 font-semibold`"
							@click="changeActiveMenu('vendors')">Vendors</h4>
						</router-link>
					<router-link :to="`/${PATH_ROOT}/digital-approval`">
						<h4 v-if="menuOfficialSignature?.canRead"
							:class="`${activeMenu === 'digital-approval'? 'text-gray-darkest': 'text-gray-light'} cursor-pointer text-xl md:text-2xl lg:text-3xl mr-6 font-semibold`"
							@click="changeActiveMenu('digital-approval')">Digital Approval</h4>
					</router-link>
				</div>
				<div v-if="activeMenu === 'dashboard' && menuReportChart?.canRead">
					<Dashboard />
				</div>
				<div v-if="activeMenu === 'home'">
					<Reports />
					<Projects :windowWidth="windowWidth"/>
				</div>
				<div v-if="activeMenu === 'vendors' && menuVendor?.canRead">
					<Vendors />
				</div>
				<div v-if="activeMenu === 'digital-approval' && menuOfficialSignature?.canRead">
					<Config />
				</div>
			</div>
		</div>
	</MainLayout>
</template>

<script>
import Vendors from '@/modules/module-dashboard/pages/Vendors.vue'
import Projects from '@/core/components/dashboard/Projects'
import { PATH_ROOT } from '@/core/constant/routeName'
import Reports from '@/core/components/dashboard/Reports.vue';
import Dashboard from '@/modules/module-dashboard/pages/Dashboard.vue';
import Config from '@/modules/module-config/pages/Config.vue';

export default {
	name: "Home",
	data() {
		return {
			activeMenu: 'dashboard',
			windowWidth: window.innerWidth,
			PATH_ROOT,
		}
	},
	computed: {
		//menu vendor
		menuVendor() {
			return this.$store.getters['dashboard/menuVendors']
		},
		menuOfficialSignature() {
			return this.$store.getters['dashboard/menuOfficialSignature']
		},
		loading: {
			get() {
				return this.$store.state.modal.loading
			},
			set(value) {
				this.$store.commit('modal/SET_LOADING', value)
			}
		},
    	menuReportChart() {
			return this.$store.getters['dashboard/menuReportChart']
		},
	},
	watch: {
		'$route.path'() {
			this.switchActiveMenu()
		}
	},
	methods: {
		changeActiveMenu(val) {
			this.activeMenu = val
		},
		switchActiveMenu() {
			switch (this.$route.path) {
				case `/${PATH_ROOT}`:
					this.activeMenu = 'home'
					break;
				case `/${PATH_ROOT}/dashboard`:
					this.activeMenu = 'dashboard'
					break;
				case `/${PATH_ROOT}/vendors`:
					this.activeMenu = 'vendors'
					break;
			}
		}
	},
	
	async mounted() {
		this.switchActiveMenu()
		window.addEventListener('resize', () => this.windowWidth = window.innerWidth)
		//get user detail
		this.loading.loadingDashboard = true
		const response = await this.$store.dispatch("dashboard/getUserDetail");
		if (response?.status === 200) {
			this.loading.loadingDashboard = false
		}
	},
	destroyed() {
		window.removeEventListener('resize', () => this.windowWidth = window.innerWidth)
	},
	components: { Vendors, Projects, Reports, Dashboard, 
		Config 
	}
};
</script>
